export default () => {

const required = document.getElementsByClassName('required');
const sendBtn = document.getElementById('send-mail');


    sendBtn.addEventListener('click',function(e){
        let requiredFlg = true;

        [].forEach.call(required,el=>{
            if(el.value === '' || el.value === null)
            {
                requiredFlg = false;
                return requiredFlg;
            } else {
                requiredFlg = true;
            }
        });

        if(requiredFlg !== true)
        {
            alert("未入力の項目があります。");
            e.preventDefault();
            return false;
        } else {
            return false;
        }

    })

};
