import windowOrientation from './modules/windowOrientation'
import viewportChange from './modules/viewportChange';
import createModal from './modules/createModal';
import smoothScroll from './modules/smoothScroll';
import validation from './modules/special/validation';

// 共通
windowOrientation();
viewportChange();
createModal();
validation();

window.onload = () => {

    document.getElementById('scroll-top').querySelector('img').addEventListener('click', function () {
        smoothScroll(0, 300);
    });

};
const ua = navigator.userAgent;
// スマホ
if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0) && (ua.indexOf('Mobile') > 0) || ua.indexOf('Windows Phone') > 0) {
    console.log('this is phone');
}
// タブレット
else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    console.log('this is tablet');
}
// PC
else {
    console.log('this is pc');
}
